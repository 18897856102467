// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import Captcha from "../../blocks/Captcha/src/Captcha";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import InventoryManagement23 from "../../blocks/InventoryManagement23/src/InventoryManagement23";
import FeedbackCollection from "../../blocks/FeedbackCollection/src/FeedbackCollection";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Payments from "../../blocks/Payments/src/Payments";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Videos4:{
 component:Videos4,
path:"/Videos4"},
Captcha:{
 component:Captcha,
path:"/Captcha"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
InventoryManagement23:{
 component:InventoryManagement23,
path:"/InventoryManagement23"},
FeedbackCollection:{
 component:FeedbackCollection,
path:"/FeedbackCollection"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
Payments:{
 component:Payments,
path:"/Payments"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},

  Home: {
component:VisualAnalytics,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
